@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";

.btn {
  display: inline-block;
  width: 242px;
  height: 82px;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 24px 32px;
  border: 0;
  outline: none;
  color: $darkGray;
  background-color: $light;
  border-radius: 55px;
  transition: all ease-in 0.1s;
  z-index: 5;

  &:hover {
    background-color: #d5d9e1;
  }

  @include md {
    height: 72px;
    font-size: 18px;
    line-height: 27px;
    padding: 16px 24px;
  }

  @include xs {
    height: 56px;
    font-size: 16px;
    line-height: 24px;
    width: 180px;
  }
}

.btnNext {
  color: $white;
  background: linear-gradient(to bottom, #80D343 0%, #6FCB2C 100%);
  mix-blend-mode: normal;
  box-shadow: 0px 4px 9px rgba(128, 211, 67, 0.35);
  border-radius: 55px;
  transition: all ease-in 0.1s;

  &:hover {
    background-position: top;
    box-shadow: none;
  }
}