//
// Form extend
//


// Color variations

.form-primary {
    .form-control {
        color: color-contrast($primary);
        background-color: transparentize(darken($primary, 10%), .5);
        border-color: darken($primary, 10%);

        &:focus {
            background-color: transparentize(darken($primary, 12%), .3);
            border: $input-border-width solid darken($primary, 12%);
        }

        // Placeholder
        &::placeholder {
            color: transparentize(color-contrast($primary), .2);
        }
    }

    .input-group-text {
        color: color-contrast($primary);
        background-color: transparentize(darken($primary, 10%), .5);
        border-color: darken($primary, 10%);
    }

    .focused {
        .input-group-text {
            color: color-contrast($primary);
            background-color: transparentize(darken($primary, 12%), .3);
            border-color: darken($primary, 12%);
        }
    }
}
