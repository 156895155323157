@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.background {
  background: radial-gradient(108.37% 108.37% at 50.13% 0%, #5F64C9 0%, #AEB8DC 100%);
  border-radius: 55px;
  margin-bottom: -42px;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 168px);
  min-height: 650px;

  @include xs {
    height: calc(100vh - 176px);
    max-height: 640px;
    min-height: 380px;
  }
}

.circles {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 68vh;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 32px 62px;
  max-width: 572px;
  height: 100%;

  @include xs {
    padding: 32px 32px 48px;
  }

  h1 {
    height: 144px;

    @include md {
      height: 106px;
    }

    @include xs {
      height: 76px;
    }
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 572px;
  z-index: 5;

  @include xs {
    padding: 0 20px;
  }
}

.flagButton {
  border: 1px solid #E9EEF6 !important;
  height: 84px;
  width: 104px;
  border-radius: 15px 0 0 15px !important;
  left: 0;
  background-color: #E9EEF6 !important;

  @include xs {
    width: 60px !important;
  }

  @include md {
    height: 76px !important;
  }

  @include xs {
    height: 58px !important;
  }

  & > div {
    padding-left: 36px !important;
    width: 104px !important;

    @include xs {
      width: 60px !important;
      padding-left: 18px !important;
    }
  }

  & > div > div > div {
    left: 24px !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top: 6px solid #6165AA !important;

    @include xs {
      left: 20px !important;
    }
  }
}

:global .react-tel-input {
  z-index: 30;
}

:global .react-tel-input .flag-dropdown.open {
  background-color: #E9EEF6 !important;
  border-radius: 15px 0 0 15px !important;
}

:global .react-tel-input:hover .flag-dropdown.open {
  background-color: #E9EEF6 !important;
  border-radius: 15px 0 0 15px !important;
}

:global .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #E9EEF6 !important;
}

:global .react-tel-input .selected-flag {
  background-color: #E9EEF6 !important;
  border-radius: 15px 0 0 15px !important;

  @include xs {
    height: 58px;
  }
}

:global .react-tel-input .selected-flag:hover {
  background-color: #E9EEF6 !important;
  border-radius: 15px 0 0 15px !important;
}

:global .react-tel-input .selected-flag .arrow {
  transition: all ease-in 0.2s;
}

:global .react-tel-input .selected-flag .arrow.up {
  border-bottom: none;
  transform: rotate(180deg);
}

:global .react-tel-input .country-list {
  z-index: 30;
  text-align: start;
}

.inputContainer {
  position: relative;
  height: 84px !important;
  border: 1px solid #E9EEF6 !important;
  padding-left: 120px !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: $darkGray !important;
  border-radius: 15px !important;
  width: 100% !important;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.17) !important;
  mix-blend-mode: normal !important;

  @include xs {
    padding-left: 68px !important;
  }

  @include md {
    height: 76px !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }

  @include xs {
    height: 60px !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  &:hover {
    border-radius: 15px !important;
  }
}

.numberContainer {
  border-radius: 15px 0 0 15px;
  width: 100%;
}