@import "../../assets/styles/colors";


.container {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
  z-index: 22;
}

.overlay {
  background: black;
  opacity: 0.6;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  width: fit-content;
  max-width: 635px;
  padding: 42px;
  background: white;
  position: relative;
  border-radius: 15px;

  & > h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    text-transform: uppercase;
    color: $purple;
    margin-bottom: 0;
    text-align: center;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .body {
    margin-top: 18px;
  }
}
