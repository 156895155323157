@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";

textarea:focus-visible {
  outline: none !important;
}

.textarea {
  background: $white;
  mix-blend-mode: normal;
  border: 1px solid #E9EEF7;
  box-sizing: border-box;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.17);
  border-radius: 15px;
  height: 240px;
  padding: 20px 30px;
  font-size: 18px;
  line-height: 27px;
  color: $darkGray;
  resize: none;
  z-index: 5;

  @include xs {
    height: 198px;
    font-size: 16px;
    line-height: 24px;
  }
}
