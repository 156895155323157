@import "../../assets/styles/mixins";

.container {
  position: relative;
  display: block;
  max-width: 650px;
  width: 100%;
  margin: 0 auto 0;
  min-height: 800px;

  @include xs {
    min-height: 530px;
  }
}