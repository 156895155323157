@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.background {
  background: radial-gradient(108.37% 108.37% at 50.13% 0%, #5F64C9 0%, #AEB8DC 100%);
  border-radius: 55px;
  margin-bottom: -436px;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 168px);
  min-height: 650px;

  @include md {
    margin-bottom: -409px;
  }

  @include xs {
    height: calc(100vh - 176px);
    max-height: 640px;
    min-height: 380px;
    margin-bottom: -282px;
  }
}

.circles {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 68vh;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 32px 454px;
  max-width: 572px;
  height: 100%;

  @include md {
    padding-bottom: 422px;
  }

  @include xs {
    padding: 32px 32px 288px;
  }

  h1 {
    height: 144px;

    @include md {
      height: 106px;
    }

    @include xs {
      height: 76px;
    }
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 572px;
  z-index: 5;


  @include xs {
    padding: 0 20px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 28px auto 28px;
  padding: 0 32px;
  max-width: 572px;
  z-index: 5;


  button:first-child {
    margin-right: 8px;
  }

  @include xs {
    padding: 0 20px;
  }

  @include md {
    margin-top: 24px;
  }

  @include xs {
    margin-top: 18px;
  }
}

.uploadContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 55px;
  padding: 96px 70px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.17);
  min-height: 224px;

  @include xs {
    padding: 34px 28px;
  }
}

.uploadContent {
  display: block;
  text-align: center;

}

.uploaded {
  padding: 0;
  max-height: 402px;
  background: transparent !important;
  box-shadow: none;
  z-index: 10;
  margin-top: -200px;
  margin-bottom: 200px;

  @include md {
    margin-top: -280px;
  }

  @include xs {
    margin-top: -140px;
    margin-bottom: 80px;
  }

  video {
    z-index: 30;
    max-height: 600px;
    border-radius: 24px;
    max-width: 600px;

    @include xs {
      max-height: 380px;
      max-width: 335px;
    }
  }

  @media (max-height: 640px) {
    margin-top: -80px;

    video {
      max-height: 340px;
    }
  }

  @media (max-height: 600px) {
    margin-top: -80px;

    video {
      max-height: 300px;
    }
  }
}

.videoUploaded {
  display: block;
  text-align: center;
  opacity: 1;
}

.iconPlane {
  position: absolute;
  top: -12px;
  left: -8px;
  width: 80px;
  height: 50px;
}

.iconUpload {
  width: 80px;
  height: 80px;
}

.labelUpload {
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  color: $lightGray;
  margin-top: 48px;

  @include md {
    font-size: 20px;
    line-height: 29px;
    margin-top: 32px;
  }

  @include xs {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  }
}

.button {
  bottom: -200px;
  position: absolute;
  background: linear-gradient(101.74deg, #888FDE 26.36%, #4752D1 98.26%);
  box-shadow: 0px 4px 9px rgba(120, 127, 208, 0.35);
  display: inline-block;
  width: 242px;
  height: 82px;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 26px 32px 0;
  border: 0;
  outline: none;
  color: white;
  background-color: $light;
  border-radius: 15px;
  transition: all ease-in 0.1s;
  z-index: 20;

  &:hover {
    background-color: #d5d9e1;
  }

  @include md {
    height: 72px;
    font-size: 18px;
    line-height: 27px;
    padding: 22px 24px 0;
  }

  @include xs {
    height: 56px;
    font-size: 16px;
    line-height: 24px;
    padding-top: 18px;
    bottom: -80px;
  }
}

.buttonDisable {
  color: $darkGray;
  background: $light;
  opacity: 0.5;
  box-shadow: none;
}

.modalContent {
  color: $darkGray;
  text-align: center;
}

.modalImage {
  margin-top: 16px;
  height: 160px;
}

.agree {
  z-index: 10;
  color: $gray;
  display: flex;
  font-weight: bold;
  padding: 20px;
  background-color: $white;
  border-radius: 15px;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.17);
  margin-top: 12px;

  &.error {
    color: $red;

    svg path {
      color: $red;
    }
  }

  p {
    margin-left: 8px;
    margin-bottom: 0;
    font-weight: bold;
    line-height: 1.5;
  }
  a {
    margin-left: 6px;
    color: $brightYellow;
  }

  @include xs {
    margin-top: 12px;
  }
}