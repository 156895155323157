@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.background {
  background: radial-gradient(108.37% 108.37% at 50.13% 0%, #5F64C9 0%, #AEB8DC 100%);
  border-radius: 55px;
  margin-bottom: -42px;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 168px);
  min-height: 650px;

  @include xs {
    height: calc(100vh - 176px);
    max-height: 640px;
    min-height: 380px;
  }
}

.circles {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 68vh;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 40px 32px 62px;
  max-width: 572px;
  height: 100%;

  @include xs {
    padding: 32px 32px 48px;
  }

  h1 {
    max-height: 144px;
    height: 100%;

    @include md {
      max-height: 106px;
      height: 100%;
    }

    @include xs {
      max-height: 76px;
      height: 100%;
    }
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 572px;
  z-index: 5;

  @include xs {
    padding: 0 20px;
  }
}