$white: #ffffff;
$darkGray: #4b4b4b;
$gray: #8d8f93;
$lightGray: #c0c0c0;
$light: #dde1ea;
$purple: #4C52B1;
$activePurple: #686ECB;
$lightPurple: #8188D2;
$brightYellow: #FEDC5E;
$red: #DB4437;
