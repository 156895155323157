@import "src/assets/styles/colors";
@import "src/assets/styles/mixins";

.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 50px;
  line-height: 72px;
  text-align: center;
  color: $white;

  @include md {
    font-size: 46px;
    line-height: 54px;
  }

  @include xs {
    font-size: 28px;
    line-height: 34px;
  }
}

.p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: $darkGray;
}