.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  /*align-items: center;*/
  /*justify-content: center;*/
  /*width: 100%*/
  /*margin-left: 7.5%;*/
  /*margin-right: 7.5%;*/
  /*max-width: 100%;*/
}

.react-player {
  /*width: 50%;*/
  position: absolute;
  align-content: center;

  /*float: left;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  top: 0;
  /*max-width: 100%;*/
  /*left: 0;*/

}

.cardContainer {
  height: 100vh;
  width: 100%;
}

.mainCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../img//reciver/mainCircle.png');
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  /* height: 20vw; */
}

.middleCircle {
  background-image: url('../img//reciver/middleCircle.png');
  height: 73vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 92vw;
  /* padding: 40vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle {
  background-image: url('../img//reciver/inner.png');
  height: 48vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 92vw;
  /* padding: 24vh; */
}

.heading {
  font-family: 'Poppins', sans-serif;
  position: absolute;
  top: 4vh;
  right: 0;
  left: 0;
  font-size: 58px;
  font-weight: 800;
  line-height: 78px;
  color: white;
  text-align: center;
}

.RecipientimgCont {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  color: white;
  text-align: center;
}

.Recipientimg, .Recipientback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40vw;
  margin: auto;
}

.Recipientimg {
  height: 40vw;
  bottom: 0;
}

.Recipientback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 25vw;
  border-radius: 35px;
  border: 10px solid;
  height: 25vw;
}

.video {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  border-radius: 35px;
}

.video video {
  border-radius: 30px;
}

.plane {
  height: 51px;
  width: 66px;
  position: absolute;
  z-index: 11;
  left: -2vw;
  top: -3vw;
}

.RecipienttextCont {
  position: absolute;
  bottom: 2vw;
  text-align: center;
  left: 0;
  right: 0;
}

.Recipienttext {
  font-size: 3vh;
  font-weight: 800;
  color: white;
  word-wrap: break-word;
  width: 421px;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .Recipientback {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 60vw;
    padding-bottom: 61px;
    padding-right: 0px;
    height: 60vw;
    background: black;
  }

  .plane {
    height: 51px;
    width: 66px;
    position: absolute;
    z-index: 11;
    left: -1vw;
    top: -4vw;
  }

  .Recipienttext {
    font-size: 3vw;
    font-weight: 800;
    color: white;
    word-wrap: break-word;
    /* width: 421px; */
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
  }

}

@media screen and (max-width: 633px) {
  .plane {
    height: 51px;
    width: 66px;
    position: absolute;
    z-index: 11;
    left: -1vw;
    top: -6vw;
  }

  .mainCircle {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-image: url('../img//reciver/mainCircle.png');
    /* height: 100vh; */
    /* background-repeat: no-repeat; */
    background-size: cover;
    /* background-position: center; */


  }

  .middleCircle {
    background-image: url('../img//reciver/middleCircle.png');
    height: 73vh;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-position: center; */
    /* width: 92vw; */

    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .innerCircle {
    background-image: url('../img//reciver/inner.png');
    height: 48vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 92vw;

  }
}

@media screen and (max-width: 450px) {
  .plane {
    height: 51px;
    width: 66px;
    position: absolute;
    z-index: 11;
    left: -1vw;
    top: -10vw;
  }

  .RecipienttextCont {
    top: 88vh;
  }

  .Recipientimg {
    width: 100vw;
    height: 80vw;
  }

  .mainCircle {
    background-image: url('../img//reciver/mainCircle.png');
    background-size: cover;
    background-position: center;
  }

  .middleCircle {

    background-image: url('../img//reciver/middleCircle.png');
    background-size: cover;
    background-position: center;

  }

  .innerCircle {
    background-image: url('../img//reciver/inner.png');
    height: 48vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 92vw;
    /* padding: 24vh; */
  }

  .Recipienttext {
    font-size: 15px;
    font-weight: 800;
    color: white;
    word-wrap: break-word;
    width: 100vw;
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
  }

  .Recipientback {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 90vw;
    height: 90vw;
    padding-bottom: 61px;
    padding-right: 0px;
  }

  .video {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .heading {
    font-size: 46px;
    line-height: 54px;
  }
}

@media (max-width: 575px) {
  .heading {
    font-size: 32px;
    line-height: 38px;
  }
}