@import "src/assets/styles/mixins";

.imageContainer {
  position: relative;
  max-width: 508px;
  max-height: 508px;
  width: 100%;
  height: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  img {
    max-height: 405px;
  }

  @include md {
    margin-top: 72px;
  }

  @include xs {
    height: 30vh;
    margin-top: 24px;
  }
}

.imageIcon {
  z-index: 10;
  max-width: 375px;
  max-height: 375px;
  width: 100%;
  height: inherit;
}

.imageIconSvg {
  z-index: 10;
  max-width: 375px;
  max-height: 375px;
  width: 100%;
  height: inherit;

  & svg {
    z-index: 10;
    max-width: 375px;
    max-height: 375px;
    width: 100%;
    height: inherit;
  }
}


.image {
  z-index: 5;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.circles {
  left: 0;
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 650px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 410px) and (max-width: 575px) {
  .message {
    max-height: 175px !important;
    width: auto;
  }
}



