@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";

$merchant-primary-color: #4e53ba !default;
$merchant-primary-color: #{var(--merchant-primary-color)};

body {
  font-family: 'Poppins', Roboto, sans-serif;
}

button, input, textarea {
  font-family: 'Poppins', Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  * {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  input,
  textarea {
    -webkit-user-select: text;
    -webkit-touch-callout: default;
  }
}

.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 58px;
  line-height: 78px;
  text-align: center;
  color: $white;
  //text-shadow: 9px 8px 0px rgba(0,0,0,0.15);
  //text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  text-shadow: 2px 2px 0px  rgba(0, 0, 0, 0.3), -2px -2px 0px  rgba(0, 0, 0, 0.3), 2px -2px 0px rgba(0, 0, 0, 0.3), -2px 2px 0px rgba(0, 0, 0, 0.3);
  z-index: 5;

  @include md {
    font-size: 46px;
    line-height: 54px;
  }

  @include xs {
    font-size: 32px;
    line-height: 38px;
  }
}

.p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: $white;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}


.headerLogo {
  width: 8em;
  margin: 0 auto 24px;
  padding: 0 12px;

  //font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.90);
  transition: all 300ms;
  color: #555;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //flex-direction: column;
  //overflow: hidden;
  padding: 1em;
  margin: 0.5em;
  //flex: 0 0 150px;
  //height: 150px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;


  img {
    align-content: center;
    align-items: center;
    align-self: center;
    max-height: 36px;
    //background-color: #ffffff;
    //box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  }

  a {
    cursor: pointer;
  }

  @include xs {

    img {
      //margin-left: 12px;
    }
  }
}

.background {
  //background: radial-gradient(108.37% 108.37% at 50.13% 0%, $merchant-primary-color 0%, #AEB8DC 100%);
  background-color: #013f7e;
  height: 100%;
  min-height: 100vh;
  padding-top: 12px;
  padding-bottom: 24px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include xs {
    padding-top: 8px;
    padding-bottom: 0;
  }
}

.circles {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;

  @include xs {
    height: 100%;
    background-position: top 35% left 0;
  }
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  z-index: 5;
  position: relative;

  @include xs {
    margin-top: 32px;
  }
}

.videoContainer {
  position: relative;
  display: block;
  border-radius: 35px;
  border: 10px solid $white;
  max-width: 350px;
  line-height: 0;
  z-index: 5;

  video {
    width: 100%;
    border-radius: 24px;
  }

  @include xs {
    video {
      max-height: 430px;
    }
  }
}

.text {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  z-index: 5;
  position: relative;

  p {
    max-width: 350px;
  }

  @include xs {
    margin-top: 36px;
  }
}

.plane {
  position: absolute;
  top: -34px;
  left: 0;
  height: 50px;

  @include xs {
    height: 34px;
    top: -24px;
  }
}

.animationContainer {
  position: relative;
  display: block;
  max-width: 600px;
  line-height: 0;
  z-index: 5;
  margin-bottom: -124px;

  @include xs {
    margin-top: -32px;
    margin-bottom: -64px;
  }
}

.links {
  max-width: 978px;
  margin: 20px auto 0;
  z-index: 100;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0 12px;

  img {
    max-height: 36px;
  }

  a {
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    color: white;
    opacity: 0.8;
  }

  @include xs {
    flex-direction: column;

    img {
      max-height: 48px;
    }

    a {
      margin-top: 8px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
}
