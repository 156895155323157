@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
  margin-right: -32px;
  margin-left: -32px;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 94px;
  max-width: 494px;
  z-index: 10;
  padding: 17px 14px;
  display: flex;
  justify-content: space-between;
  background-image: url("https://storage.googleapis.com/public-vc-1/staticAssets/svg/tape.svg");
  background-repeat: no-repeat;
  background-size: contain;

  @include xs {
    max-width: 312px;
    max-height: 64px;
    padding: 12px 10px;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;

  svg {
    width: 100%;
    height: 100%;
    max-height: 94px;
    max-width: 494px;
  }

  @include xs {

    svg {
      max-width: 312px;
      max-height: 64px;
    }
  }
}

.step.active {
  background-color: $activePurple;

  svg {
    path {
      fill: $white;
    }
  }
}

.step {
  width: 88px;
  height: 60px;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $lightPurple;
  border-radius: 4px;

  svg {
    path {
      fill: $purple;
    }
  }

  @include xs {
    width: 100%;
    height: 100%;
    max-height: 35px;
    max-width: 56px;

    svg {
      width: 100%;
      height: 100%;
      max-width: 26px;
      max-height: 26px;
    }
  }
}