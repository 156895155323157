@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";

.select__wrap {
  position: relative;
  font-size: 18px;
  line-height: 27px;
  min-width: 200px;

  @include md {
    //height: 76px;
    font-size: 18px;
    line-height: 27px;
  }

  @include xs {
    //height: 60px;
    font-size: 16px;
    line-height: 24px;
  }
}

.select__label {
  position: absolute;
  top: 0;
  left: 17px;
  z-index: 1;
  pointer-events: none;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: scale(1) translateY(17px);
  color: $red;
  transform-origin: top left;

  &.hideLabel {
    display: none;
  }
}

.select__label_focused {
  transform: scale(0.8) translateX(0px) translateY(8px);
}

.error {
  font-size: 14px;
  line-height: 18px;
  color: $red;
  margin-top: 8px;
}
