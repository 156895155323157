@import "../../assets/styles/colors";
@import "../../assets/styles/mixins";

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 28px auto 28px;
  padding: 0 32px;
  max-width: 572px;
  z-index: 5;

  button:first-child {
    margin-right: 8px;
  }

  @include xs {
    padding: 0 20px;
  }

  @include md {
    margin-top: 24px;
  }

  @include xs {
    margin-top: 18px;
  }
}