@mixin popover-variant($background) {

	background-color: $background;

	.popover-header {
		background-color: $background;
		color: color-contrast($background);
	}

	.popover-body {
		color: color-contrast($background);
	}
	.popover-header{
		border-color: rgba(color-contrast($background), .2);
	}
	&.bs-popover-top {
		.arrow::after {
			border-top-color: $background;
		}
	}
	&.bs-popover-right {
		.arrow::after {
			border-right-color: $background;
		}
	}
	&.bs-popover-bottom {
		.arrow::after {
			border-bottom-color: $background;
		}
	}
	&.bs-popover-left {
		.arrow::after {
			border-left-color: $background;
		}
	}
}